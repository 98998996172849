import { mapActions } from 'vuex'
import { myMixin } from '../../../../mixins';

export default{
  mixins: [myMixin],
  data: () => ({
    collections: [],
    showImport: false,
    showMailHourTeacher: false,
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    model: {},
    show: false,
    onlyActive: true,
    showForm: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    
      headers: [
        { text: 'Código', value: 'code', align: 'center',  type: 'time'},
        { text: 'Início', value: 'start', align: 'center',  type: 'time'},
        { text: 'Fim', value: 'end', align: 'center', type: 'time'},
        { text: 'Turno', value: 'shift.name', align: 'center',},
        { text: 'Limite', value: 'limit', sortable: false, align: 'center',},
        { text: 'Total Aulas', value: 'total_times', sortable: false, align: 'center',},
        { text: 'Status', value: 'active', type: 'boolean'},
        { text: 'Ações', value: 'actions'},
    ]
    }),
    
    watch: {
      
        onlyActive(val){
            this.getData();
        },
      
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        },
        //Função para tratar quando um item for exibido para deletar
        itemDelete(item_new, item_old){
            if(item_new !== item_old && Object.keys(item_new).length > 0)
            {
                this.show = true;
                this.textDelete = "Deseja excluir o horário de " + item_new.start + " às " +  item_new.end  + "?";
            } else {
                this.show = false
                this.textDelete = '';
            }
        }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
        show(show_new, show_old) {
            
            if(show_new != show_old && !show_new) {
                this.itemDelete = {};
            }
        },

        model(item_new, item_old){
            
            if(item_new !== item_old && this.model.id)
            {
                this.showForm = true;
                this.errors = {};
            } else if(Object.keys(this.model).length === 0){
                this.showForm = false;
                this.errors = {};
            }           
        },

        showForm(show_new, show_old) {
            if(show_new != show_old && !show_new) {
                this.model = {};
            }
        },
    },
    
    methods: {
      ...mapActions('hour', ['ActionIndexBaseHours', 'ActionDeleteBaseHour']),
        
      getData(){
      
        this.error = false;
        this.message = '';
        this.loadingProgress = true;
        this.showForm = false;
        this.showImport = false;

        let payload = this.params;

        payload.with = 'shift';
        payload.column_order = 'code';

        if(this.onlyActive){
            payload.find_columns = {
                active: 1,
            };
        }else{
            delete payload.find_columns;
        }
        
        this.ActionIndexBaseHours(payload)
            .then((res) => {
                this.collections = res.data
                this.meta = res.meta
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    deleteItem()
    {
        this.deleting = true;
        this.message = '';

        this.ActionDeleteBaseHour({id: this.itemDelete.id})
            .then((res) => {
                this.message = res.message;
                this.show = false;
                this.getData();
            })
            .catch((error) =>{
                this.message = error.message;
                this.error = true;
            })
            .finally(() => {
                this.deleting = false;
            });
    }

  },

}
